import { Table } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupMembers } from '../../../features/fetch/fetchSlice';
import { useEffect } from 'react';
import { setActiveMember } from '../../../features/obj/objSlice';
import { useNavigate } from 'react-router-dom';
import { capitalize, formatMoney, getRandomColor, formatFilePath } from '../../../utils';
import { membersRoleColors } from '../../../data';
import { handlememberRole } from '../../../features/auth/authSlice';

export default function MembersTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { members } = useSelector((state) => state.fetch);
    const { user, memberRole } = useSelector((state) => state.auth);

    async function handleActiveMember(item) {
        await dispatch(setActiveMember(item));
        await navigate(`/members/${item?.gpmName}`);
    }

    async function handleFetch() {
        await dispatch(fetchGroupMembers());
    }

    useEffect(() => {}, [members]);

    useEffect(() => {
        let loggedInMember = members?.find((item) => item?.gpmUsrId === user?.jti);
        dispatch(handlememberRole(loggedInMember?.gpmRoleName));
    }, [members]);

    useEffect(() => {
        handleFetch();
    }, []);

    const columns = [
        {
            title: 'Name',
            render: (item) => (
                <>
                    <div className="w-full flex items-center gap-[.5rem]">
                        <img
                            src={item?.user?.usrLogo
                                ? formatFilePath(item?.user?.usrLogo)
                                : `https://ui-avatars.com/api/?name=${encodeURIComponent(item?.gpmName)}&background=${getRandomColor()}&color=fff`}
                            className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                            alt="avatar"
                        />
                        <span>{item?.gpmName}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Role',
            dataIndex: 'gpmRoleName',
            render: (item) => {
                const roleColor = membersRoleColors.find((role) => role.label === item)?.value;

                return (
                    <div
                        style={{
                            background: roleColor,
                        }}
                        className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                            <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                        </svg>
                        <span>{capitalize(item)}</span>
                    </div>
                );
            },
        },
        {
            title: 'Last contribution (KES |Date)',
            dataIndex: 'gpmLastContributed',
            render: (item) => (
                <span>
                    {formatMoney(item?.lastContributionAmount, 'KES')}
                    {item?.lastContributionDate ? ' | ' + moment(item?.lastContributionDate).format('Do MMM YYYY') : null}
                </span>
            ),
        },
        {
            title: 'Date joined',
            dataIndex: 'createdDate',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        },
        {
            title: 'Action',
            render: (item) =>
                memberRole === 'MEMBER' ? (
                    <span> - </span>
                ) : (
                    <button onClick={() => handleActiveMember(item)} type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                            <path
                                d="M9.69922 2.23333C12.9724 2.23333 15.8915 4.07933 17.3165 7C15.8915 9.92067 12.981 11.7667 9.69922 11.7667C6.4174 11.7667 3.50695 9.92067 2.08195 7C3.50695 4.07933 6.42604 2.23333 9.69922 2.23333ZM9.69922 0.5C5.38104 0.5 1.69331 3.19533 0.199219 7C1.69331 10.8047 5.38104 13.5 9.69922 13.5C14.0174 13.5 17.7051 10.8047 19.1992 7C17.7051 3.19533 14.0174 0.5 9.69922 0.5ZM9.69922 4.83333C10.891 4.83333 11.8583 5.804 11.8583 7C11.8583 8.196 10.891 9.16667 9.69922 9.16667C8.5074 9.16667 7.54013 8.196 7.54013 7C7.54013 5.804 8.5074 4.83333 9.69922 4.83333ZM9.69922 3.1C7.5574 3.1 5.81286 4.85067 5.81286 7C5.81286 9.14933 7.5574 10.9 9.69922 10.9C11.841 10.9 13.5856 9.14933 13.5856 7C13.5856 4.85067 11.841 3.1 9.69922 3.1Z"
                                fill="#1D191B"
                            />
                        </svg>
                    </button>
                ),
        },
    ];

    return (
        <>
            <Table
                scroll={{
                    x: 1200,
                }}
                rowKey="gpmId"
                className="mt-[1.5rem] !w-full"
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                columns={columns}
                dataSource={members}
            />
        </>
    );
}
